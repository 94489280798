import React from "react";

export default function SDILinks() {
    return (
        <div className="column text-start mx-auto mt-4">
            <span className="link-block d-flex gap-2">
                <a href="https://arxiv.org/abs/2405.15891"
            className="external-link btn btn-dark rounded-pill">
            <span className="icon">
                <i className="ai ai-arxiv"></i>
            </span>
                <span> Paper</span>
            </a>

            <a href="https://github.com/ottogin/score-distillation-via-inversion"
                className="external-link btn btn-dark rounded-pill">
            <span className="icon">
                <i className="bi bi-github"></i>
            </span>
                <span> 3D Code </span>
            </a>

            <a href="https://github.com/ottogin/score-distillation-via-inversion/blob/main/2dplayground_SDI_version.ipynb"
                className="external-link btn btn-dark rounded-pill">
            <span className="icon">
                <i className="bi bi-file-earmark"></i>
            </span>
                <span> 2D Notebook </span>
            </a>

            <a href="https://www.youtube.com/watch?v=sNjqHmA2uWQ"
                className="external-link btn btn-dark rounded-pill">
            <span className="icon">
                <i className="bi bi-youtube"></i>
            </span>
                <span> Video </span>
            </a>
        </span>
        </div>
    );
}